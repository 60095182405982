<template>
    <div class="not-found">
    
    <div class="content-not-found">
        <h1 class="not-found-title"> {{ $t('itemDetail.noResultsFound') }} </h1>
        <p class="not-found-description">{{ $t('itemDetail.thePageYouWereLookingForDoesNotExist') }} </p>    
        <a class="not-found-back" @click="goToList"> {{ $t('itemDetail.goToOurList') }} </a>
    </div>
    </div>
</template>

<script>
	import { mapState } from 'vuex'

    export default {
        computed: {
			...mapState('auth',['queryParams']),			
		},
        methods: {
            goToList(){ 
                const params = {...this.queryParams }                
                if(params){
                    delete params.idItem                
                }
                this.$router.push({ name:'results', query: {...params} })           
            },
        },
    }
</script>

<style lang="scss" scoped>
.not-found{
    position: relative;
    max-width: 1200px;
    width: 90%;
    margin: 5rem auto 2.5rem;/*centrado horizontal*/
    background-image: url('~@/assets/images/not-found-item.jpg');
    background-repeat: no-repeat;/*Para que no se repita el fondo*/
    background-size: cover;
    background-position: center;/*centra la wave*/
    height: 400px;
    // border-radius: 1rem;
    overflow: hidden;
    font-family: Century Gothic, Regular;
    // opacity: 1;
    text-shadow: 1px 1px #000000;
}
.content-not-found{       
    position: absolute; 
    left: 0; 
    right: 0; 
    top: 20%;
    margin-left: auto; 
    margin-right: auto; 
    width: 80%; /* Need a specific value to work */
    color: #fff;

}
.not-found-title{
    color: #fff;
    margin-bottom: 1.5rem;
    font-weight: bold;

}
.not-found-description{
    line-height: 1.8;
    font-weight: bold;
    margin-bottom: 2rem;
    font-size: 1.2rem;

}
.not-found-back{
    text-decoration: none;
    color: #fff;
    background-color: #0068b4;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-weight: bold;
}

@media (min-width:992px){
    .not-found{
        height: 500px;
    }
    .content-not-found{                      
        top: 35%;
        margin-left: auto; 
        margin-right: auto; 
        width: 600px; /* Need a specific value to work */        
    }
    .not-found-title{
        font-size: 3rem;        
    }
    .not-found-description{                  
        font-size: 1.8rem;
    }
}

@media (min-width:1200px){
    .not-found{
        height: 700px;
    }
}
</style>