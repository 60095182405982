<template>
  <div>
    <Navigation/>

      <div v-if="isLoadingProduct">

        <div class="wrapper-detail grid">

          <div class="breadcrumb-head">        
            <SkeletonBreadCrumb/>                               
          </div> 

          <main class="item__detail grid">
            <SkeletonItemImage/>            
          </main>

          <aside class="sidebar-right">
            <div class="sidebar__container">          
              <SkeletonItemBreakdown />                          
            </div>        
          </aside>

        </div>

      </div>

      <div v-if="!isLoadingProduct && productDetail " >
        <div class="wrapper-detail grid">

          <div class="breadcrumb-head">                      
            <BreadCrumbs :active="2" />                
          </div>    

          <main class="item__detail grid" >                    
            <ItemImage  />                     
            <ItemDescription  /> 
          </main>

          <aside class="sidebar-right">
            <div class="sidebar__container">                      
              <ItemBreakdown  />                   
            </div>        
          </aside>
                    
        </div>

      </div>

      <div v-if="!isLoadingProduct && !productDetail ">
        <NotFoundDetail/>
      </div>
            
    <Footer/>
  </div>
</template>

<script>

import { mapActions, mapMutations,  mapState } from 'vuex'
import { collapsibleCheckout } from "@/assets/js/collapsible"
import { myDropdown } from "@/assets/js/dropdown"

import { market } from '@/modules/shop/mixins/market'

import Navigation from '@/modules/shop/components/experiences/Navigation'
import Footer from '@/modules/shop/components/experiences/Footer'

import BreadCrumbs from '@/modules/shop/components/detail/BreadCrumbs'
import ItemImage from '@/modules/shop/components/detail/ItemImage'
import ItemDescription from '@/modules/shop/components/detail/ItemDescription'
import ItemBreakdown from '@/modules/shop/components/detail/ItemBreakdown'
import NotFoundDetail from '@/modules/shop/components/detail/NotFoundDetail'

import SkeletonBreadCrumb from '@/modules/shop/components/skeletons/SkeletonBreadCrumb'
import SkeletonItemImage from '@/modules/shop/components/skeletons/SkeletonItemImage'
import SkeletonItemDescription from '@/modules/shop/components/skeletons/SkeletonItemDescription'
import SkeletonItemBreakdown from '@/modules/shop/components/skeletons/SkeletonItemBreakdown'

export default {  
  mixins: [ market ],  
  components: {             
    Navigation,    
    BreadCrumbs,
    ItemImage,
    ItemDescription,
    ItemBreakdown,
    NotFoundDetail,
    Footer,     
    SkeletonBreadCrumb,  
    SkeletonItemImage,          
    SkeletonItemDescription,
    SkeletonItemBreakdown
  },
  
  async created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)   
    await this.init()    
    document.title = `${process.env.VUE_APP_NAME } | Detail`  
    if(this.productDetail){
      collapsibleCheckout()
      this.openCollapseIfViewportIsMinorThan540Px()
    }
    myDropdown()    
  }, 

  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  /*
   beforeRouteLeave (to, from, next) {
 const answer = window.confirm('Do you really want to leave?')
   if (answer) {
      next()
   } else {
      next(false)
   }
 },
  */
 
  
  data() {
    return {           
      isLoadingProduct: false,      
    }
  },
  computed:{    
    ...mapState('products',['productDetail','originSelectedProduct']),                           
    ...mapState('auth',['queryParams','lang']),  
  },
  methods: {
    ...mapActions('products',['fetchProductDetail','fetchProductRateDetail']),
    ...mapMutations('products',['setProductDetail']), 
                
    async init(){
      this.clearStorage()
      this.setQueryParams() //desde el mixin market
      this.overWriteTokenAxios() // desde el mixin market
      this.setQueryStringInUrl() // desde el mixin market      
      await this.getSigleProduct()
    },         
    existIdItem(){
      let existIdDetail= false
      if(this.queryParams){
        const { idItem } = this.queryParams        
        if( this.isANumber(idItem) ){ //si viene este query params y es un numero
          existIdDetail = true
        }
      }
      return existIdDetail
    },
    isANumber(idItem){
      return idItem ? !isNaN( idItem) : false
    },
    async getSigleProduct(){  
      this.setProductDetail(null)                  
      if( this.existIdItem() ){
        this.isLoadingProduct = true
        const payload = {            
          adults: parseInt(this.queryParams.adults),
          children: parseInt(this.queryParams.children),
          dateRange: this.queryParams.date,
          endPoint: 'products',
          idsub:this.queryParams.subCategory,
          idItem: parseInt(this.queryParams.idItem),
        }
     
        let product
        if( this.originSelectedProduct === 'legacy' ){
         product = await this.fetchProductDetail(payload)
        } else {
         product = await this.fetchProductRateDetail(payload)
        }
        
        if(product){
          this.setProductDetail(product)                  
        }       
        this.isLoadingProduct = false
      }
      if(!this.existIdItem()){
        this.setProductDetail(null) 
      }
    },              
    openCollapseIfViewportIsMinorThan540Px(){
      const mediaQuery = window.matchMedia("(max-width: 539px)")
      const { matches } = mediaQuery
      if(matches){ //si matches es true, abro el collapsible de categorías, en pantallas grabdes abro categorias
        document.querySelector(".collapse__checkout").click() // simulo el click al collapse categorias                 
      }
    }
  },

  watch:{    
    async lang(){      
      this.overWriteTokenAxios()
      await this.init()           
      if(this.productDetail){
        collapsibleCheckout()
        this.openCollapseIfViewportIsMinorThan540Px()
      }
    }
  }
 
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import "@/assets/scss/detail-item.scss";  
</style>
