<template>
	<div class="container-b" v-if="queryParams">		
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item"><a :class="{'active': active === 1}" @click="goToList">{{ $t('itemDetail.list') }}</a></li>
				<li class="breadcrumb-item "><a :class="{'active': active === 2}" @click="goToReserve" v-if="queryParams.idItem">{{ $t('itemDetail.detail') }}</a></li>
				<li class="breadcrumb-item " v-if="!isEmptyCart"> <a :class="{'active': active === 3}" @click="goToCheckout">{{ $t('itemDetail.checkout') }}</a> </li>
			</ol>
		</nav>
	</div>
</template>

<script>
	import { mapState, mapMutations, mapGetters } from 'vuex'
    export default {
		props:{
			active:{
				type: Number,
				required: true,
			}
		},
		computed: {
			...mapState('auth',['queryParams']),
            ...mapGetters('shop',['isEmptyCart']),
			
		},
		methods: {
			...mapMutations('auth',['setDataQueryParams']), 
			goToList(){ 
                const params = {...this.queryParams }                
                if(params){
                    delete params.idItem                
                }
                this.$router.push({ name:'results', query: {...params} })           
            },
			goToReserve(){      
				this.setDataQueryParams({...this.queryParams, idItem: this.queryParams.idItem })
				this.$router.push({ name:'item-detail', query: {...this.queryParams} })
			},
			goToCheckout(){      
				this.setDataQueryParams({...this.queryParams, idItem: this.queryParams.idItem })
				this.$router.push({ name:'checkout', query: {...this.queryParams} })
			},
		},       
    }
</script>

<style lang="scss" scoped>
.container-b{
	font-family: Century Gothic, Regular;
	color: rgb(20, 20, 20);
	font-weight: 500;
}
.active {
	// background: rgb(206, 39, 39);
	color: #2C6CBF;
	font-weight: bold;
}

</style>