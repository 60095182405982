
export const collapsibleFilters = () => {
    const coll = document.getElementsByClassName("sidebar__item--title") //este elemento obtiene la clase donde se hace clic
    
    let i

    for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {                      
            this.classList.toggle("active")
            const content = this.nextElementSibling            
            if (content.style.maxHeight){
                content.style.maxHeight = null
            } else {
                content.style.maxHeight = content.scrollHeight + "px"
            } 
        })
    }
}

export const collapsibleCheckout = () => {

    const collapseIcon = document.querySelector('.collapse__checkout') // querySelector, is the only one 
    const contentCollapse = document.querySelector('.sidebar__content') // querySelector, is the only one   
    const btnCheckout = document.querySelector('.btn-checkout') // querySelector, is the only one   
    const titleItem = document.querySelector('.title__item') // querySelector, is the only one   


    collapseIcon.addEventListener('click', () => toggleContent() )
    
    const toggleContent = () => {        
        collapseIcon.classList.toggle("active")
        if( contentCollapse.style.maxHeight ){
            contentCollapse.style.maxHeight = null
            btnCheckout.style.display = 'block'
            titleItem.style.display = 'none'
        } else {
            contentCollapse.style.maxHeight = contentCollapse.scrollHeight + "px"
            btnCheckout.style.display = 'none'
            titleItem.style.display = 'block'

        }
    }
}