<template>
  <div class="sidebar__item">  
    <div class="sidebar__header">
      <div class="checkout-head">
        <button class="btn-checkout" @click="goToCheckout(productDetail)" > {{ $t('itemDetail.proceedToCheckout') }} </button>
        <p class="title__item">{{productDetail.name }}</p>
      </div>
      <div class="collapse__checkout">
        <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 5L7.5 12L1 5" stroke="#1D1929" stroke-linecap="square"/>
        </svg>
      </div>
    </div>
    <div class="sidebar__content">

      <div class="item-content">
        <label class="search_label"> {{ $t('itemDetail.date') }} </label>                    
        <flat-pickr              
          class="form-control mb-1"
          placeholder="When..."              
          v-model="queryParams.date"
          @input="setDateQueryParams"    
          :config="dateConfig"   
		  :disabled="isAGroup || originSelectedProduct === 'rates' "
        />                    
      </div> 
      <div class="item-content" v-if="!isAGroup">
        <div class="guests-input">            
          <div class="total-guests">                
            <span>No. Persons</span>                                                
            <button class="btn-guests" type="button" :disabled="true" > {{totalGuest}} </button>
          </div>                    
        </div>                
        <div class="guests-pax" >                
          <span  class="pax-type"> {{ $t('itemDetail.adults') }}</span>                  
          <div class="pax-qty">
            <button class="btn-qty" type="button" :disabled="queryParams.adults == 1"  @click="setPaxAdults('-')" v-if="originSelectedProduct !== 'rates'" >
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7.5H14" stroke="#34303E"/>
              </svg>
            </button>
            <span class="qty-number"> {{ queryParams.adults }} </span>
            <button class="btn-qty" type="button" @click="setPaxAdults('+')" :disabled="queryParams.adults === maxAdultsPax" v-if="originSelectedProduct !== 'rates'">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 1V14M1 7.5H14" stroke="#34303E"/>
              </svg>
            </button>
          </div>
        </div>      
        <div class="guests-pax" v-if="detailChild">                
          <span  class="pax-type"> {{ $t('itemDetail.children') }}</span>                  
          <div class="pax-qty">
            <button class="btn-qty" type="button" :disabled="queryParams.children == 0" @click="setPaxChildren('-')" v-if="originSelectedProduct !== 'rates'">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7.5H14" stroke="#34303E"/>
              </svg>
            </button>
            <span class="qty-number"> {{queryParams.children}} </span>
            <button class="btn-qty" type="button"  @click="setPaxChildren('+')" :disabled="queryParams.children === maxChildrenPax" v-if="originSelectedProduct !== 'rates'">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 1V14M1 7.5H14" stroke="#34303E"/>
              </svg>
            </button>
          </div>
        </div>           
      </div>  

      <div class="item-content mt-2" v-if="isAGroup">
        <span  class="pax-type"> Group: </span>                  
        <span > {{ productDetail.detail[0].num  }} </span>
      </div>
      
      <div class="item-content-footer">
        <div class="item-content-footer__total">
          <span>{{ $t('itemDetail.total') }}  * </span>           
          <span>$ {{ !isAGroup ? totalBreakdown(productDetail) : roundedTotal }} USD </span>           
        </div>
        <span class="tax-text">* {{ $t('itemDetail.taxesAreIncluded') }} </span>
      </div>  
          
      <button class="button button--flex " :class="!isEmptyCart ? 'button--pay':''" @click="goToCheckout(productDetail)" >                
        {{ $t('itemDetail.proceedToCheckout') }} 
      </button>            
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

import { market } from '@/modules/shop/mixins/market'
import { toDecimal, currentDateAddOneDay } from '@/helpers/helpers'
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es.js';
  import { english } from 'flatpickr/dist/l10n/default.js';

export default {  
  mixins: [ market ],  
  components: { 
    flatPickr,
  },   
  computed:{
    ...mapState('shop',['cart']),    
    ...mapState('products',['productDetail','originSelectedProduct']),               
    ...mapGetters('products',['filteredProducts']), 
    ...mapGetters('shop',['isEmptyCart']),
    ...mapState('auth',['queryParams','lang']),
   
    dateConfig(){        
        const langLocale = this.lang
        const langs = { en: english, es: Spanish }

        return {
          // mode: "range",
          minDate: currentDateAddOneDay(),
          maxDate: '',        
          altInput: true,
          altFormat: "l, F j, Y",
          locale: langs[langLocale], //esto genera algo así langs.en o langs.es
          // dateFormat: "Y-m-d",
        }
      },
    totalGuest()  {
      return parseFloat(this.queryParams.adults) + parseFloat(this.queryParams.children)
    },                       
    maxAdultsPax()  {
      return process.env.VUE_APP_ADULTS_MAX_PAX ? parseFloat(process.env.VUE_APP_ADULTS_MAX_PAX) : false
    },
    maxChildrenPax()  {
      return process.env.VUE_APP_ADULTS_MAX_PAX ? parseFloat(process.env.VUE_APP_CHILDREN_MAX_PAX) : false
    },
    isAGroup(){
      const adultDetail = this.productDetail.detail.find( item => item.typeDisplay === 'group' )
      return adultDetail ? true : false
    } ,
    detailAdult(){

      return this.productDetail.detail.find( d => d.detailDesc?.toLowerCase() == 'adult' || d.detailDesc?.toLowerCase() == 'adulto' || d.typeDisplay == 'adult' )
     
    },
    detailChild(){

      return this.productDetail.detail.find( d => d.detailDesc?.toLowerCase() == 'child' || d.detailDesc?.toLowerCase() == 'menor' || d.typeDisplay == 'child' )
      
    },    
    roundedTotal(){
		  return Math.round( this.productDetail.total ).toFixed( 2 ) 
	  },        
  },
  methods: {
    ...mapActions('products',['fetchProducts','fetchProductDetail']),
    ...mapMutations('products',['setProductDetail','resetQtySingleProductDetail']),     
    ...mapMutations('shop',['addProductInCart','clearCart','updatePaxInCart']),     
    ...mapMutations('auth',['setDataQueryParams','setDateInQueryParams','setCategoryQueryParam', 'setSubCategoryQueryParam','setDestinationInQueryParams','setNumberChildren','setNumberAdults']), 
    toFixed(valor){
      return toDecimal(valor)
    },
    setDateQueryParams(date){
      this.setDateInQueryParams(date) 
      this.calculateBreakdownInCart()
      this.$router.replace({ query: {...this.queryParams} }).catch(() => {})            
    },
    totalBreakdown(item){        
        const { detail } = item
        
        if( detail.length > 0  ){                      
            const totalAdults = this.detailAdult ? parseFloat ( this.detailAdult.saleprice ) * parseFloat(this.queryParams.adults) : 0
            const totalChild = this.detailChild ? parseFloat ( this.detailChild.saleprice ) * parseFloat(this.queryParams.children) : 0            
            return toDecimal( totalAdults + totalChild )            
        }
        return toDecimal(0)
    },
    goToCheckout(item){      
        this.setDataQueryParams({...this.queryParams, idItem: item.id })  
        this.clearCart()    
        this.initAddToCart({...item})// desde el mixin market
        this.calculateBreakdownInCart()
        this.$router.push({ name:'checkout', query: {...this.queryParams} })
    },
    setPaxChildren(operator){
      const children = operator === '-' ? parseFloat( this.queryParams.children ) - 1 : parseFloat( this.queryParams.children) + 1
      this.setNumberChildren(children)
      this.updatePaxInCart({ adults: parseInt(this.queryParams.adults), children, type: 'children'})
      this.calculateBreakdownInCart()
      this.$router.replace({ query: {...this.queryParams} }).catch(() => {})            
    },
    setPaxAdults(operator){
      const adults = operator === '-' ? parseFloat( this.queryParams.adults ) - 1 : parseFloat( this.queryParams.adults) + 1            
      this.setNumberAdults(adults)
      this.updatePaxInCart({ adults, children: parseInt(this.queryParams.children), type: 'adults'})
      this.calculateBreakdownInCart()
      this.$router.replace({ query: {...this.queryParams} }).catch(() => {})            
    },
    openCollapseIfViewportIsMinorThan540Px(){
      const mediaQuery = window.matchMedia("(max-width: 539px)")
      const { matches } = mediaQuery
      if(matches){ //si matches es true, abro el collapsible de categorías
        document.querySelector(".collapse__checkout").click() // simulo el click al collapse categorias                 
      }
    }
  },
 
}
</script>

<style  lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import "@/assets/scss/item-breakdown.scss";  
</style>