import { render, staticRenderFns } from "./SkeletonItemBreakdown.vue?vue&type=template&id=54010dda&scoped=true&"
var script = {}
import style0 from "./SkeletonItemBreakdown.vue?vue&type=style&index=0&id=54010dda&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54010dda",
  null
  
)

export default component.exports